import { ReturnListItem } from "api/wms/returns/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { formatHours } from "pages/wms/shared/utils/formatHours";
import { formatMinutes } from "pages/wms/shared/utils/formatMinutes";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { combineDateFormat } from "../unloadingsList/utils/combineDateFormat";
import { dateFns, getStandardTimeFormat, pluralize } from "utilities";
import { ReturnStart } from "./subcomponents/ReturnStart";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { wmsReturnConstants } from "constants/returns";
import { ReturnDate } from "./subcomponents/ReturnDate";
import { wmsReturnsActions } from "api/wms/returns/actions";

export const useReturnsColumns = () => {
  const handleDownloadReturnPdf = wmsReturnsActions.useDownloadReturnPdf();

  return useCreateTableColumns<ReturnListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 220,
      }),
      columnHelper.text(
        row =>
          row.suppliers && Boolean(row.suppliers.length)
            ? row.suppliers.map(supplier => supplier.name).join(", ")
            : null,
        {
          header: "producenci",
          size: 250,
        },
      ),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 130,
        cell: info => {
          const status = info.getValue();
          return (
            <Tag
              label={wmsReturnConstants.statusOptions[status].label}
              startIcon={MdiPoint}
              variant={wmsReturnConstants.statusOptions[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.ramp, {
        header: "rampa",
        size: 40,
        cell: info => {
          const ramp = info.getValue();
          if (!ramp)
            return <EmptyValue className="d-flex justify-content-center w-100" fontWeight="700" />;
          return (
            <div className="d-flex justify-content-center w-100">
              <Tag label={ramp.name} variant="warning" />
            </div>
          );
        },
      }),
      // TODO: Missing backend
      columnHelper.accessor(row => row, {
        header: "zwrócone paczki",
        size: 100,
        cell: info => {
          return <EmptyValue />;
        },
      }),
      columnHelper.accessor(row => row, {
        id: "estimatedDateOfArrival",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            przyjazd
          </Typography>
        ),
        size: 100,
        cell: info => {
          const date = info.getValue().estimatedDateOfArrivalDate;
          const time = info.getValue().estimatedDateOfArrivalTime;
          const combinedDate = combineDateFormat(date, time);

          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {!combinedDate ? "---" : `${getStandardTimeFormat(combinedDate)}`}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "start",
        size: 120,
        cell: info => {
          return <ReturnStart fontSize="12" fontWeight="700" returnDetails={info.getValue()} />;
        },
      }),
      columnHelper.accessor(row => row.finishedAt, {
        header: "zakończenie",
        size: 90,
        cell: info => {
          const finishedAt = info.getValue();
          return <ReturnDate date={finishedAt} fontSize="12" fontWeight="700" />;
        },
      }),
      columnHelper.text(
        row => {
          if (!row.duration || !Boolean(row.duration.length)) return null;

          const matchDays = row.duration.match(/^\d+\s/);
          const parsedTime = dateFns.parse(
            matchDays ? row.duration.slice(matchDays[0].length) : row.duration,
            "HH:mm:ss.SSSSSS",
            new Date(),
          );
          if (matchDays) {
            const days = parseInt(matchDays[0], 10);
            return `${days} ${pluralize.pl(days, {
              singular: "dzień",
              plural: "dni",
              other: "dni",
            })}, ${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
          }
          return `${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
        },
        {
          header: "czas trwania",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row, {
        header: "etykieta",
        size: 45,
        cell: info => {
          const returnDetails = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadReturnPdf(returnDetails.id, returnDetails.signature);
                }}
                variant="transparent"
              />
            </div>
          );
        },
      }),
    ];
  });
};
