import { useMutation } from "hooks/useMutation";
import { wmsReturnFileFactory, wmsReturnsApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { CreateReturn } from "./models";
import { FormikHelpers } from "formik";
import { wmsReturnsKeys } from "./keys";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { UUID } from "api/types";
import { fileDownloader } from "fileDownloader";

const useGetReturns = createPaginatedApiQuery(wmsReturnsApi.getReturns);

const usePostReturn = (close: () => void) => {
  const createReturn = useMutation(wmsReturnsApi.postReturn, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsReturnsKeys.list());
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano zwrot",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateReturn, actions: FormikHelpers<CreateReturn>) => {
    createReturn.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useDownloadReturnPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, signature: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsReturnFileFactory.returnPdf({
      id,
      signature,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

export const wmsReturnsActions = {
  useDownloadReturnPdf,
  useGetReturns,
  usePostReturn,
};
