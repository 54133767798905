import { queryFetch } from "apiConnectors/queryFetch";
import { CreateReturn, ReturnListItem } from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { wmsReturnsKeys } from "./keys";
import { dateFns } from "utilities";

const getReturns = (search: string = ""): ApiFetcher<Pagination<ReturnListItem>> => ({
  key: wmsReturnsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/returns/web/items" + search,
    }),
});

const postReturn = (data: CreateReturn) =>
  queryFetch<void>({
    method: "POST",
    url: "/deliveries-and-returns/create-wms-return",
    data,
  });

export const wmsReturnFileFactory = (() => {
  const getDate = () => dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  const formatSignature = (signature: string) => signature.replace(/\//g, "-");

  return {
    returnPdf: (returnDetails: Pick<ReturnListItem, "id" | "signature">) => ({
      url: `wms/returns/get-labels/${returnDetails.id}`,
      name: `${formatSignature(returnDetails.signature)}-${getDate()}`,
    }),
  };
})();

export const wmsReturnsApi = {
  getReturns,
  postReturn,
};
