import { ReturnStatus } from "api/wms/returns/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const statusOptions: Record<
  ReturnStatus,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  NOT_STARTED: { label: "nierozpoczęto", variant: "outline" },
  IN_PROGRESS: { label: "w trakcie", variant: "info" },
  FINISHED: { label: "zakończono", variant: "success" },
};

export const wmsReturnConstants = {
  statusOptions,
};
