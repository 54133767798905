import { UUID } from "api/types";
import { PackageInBasket } from "api/wms/baskets/models";
import { wmsUtilsActions } from "api/wms/utils/actions";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { errorStatusToMessageDict } from "components/utils";
import { PropsWithChildren } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackageInBasketColumns = () => {
  return useCreateTableColumns<PackageInBasket>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "paczka",
        size: 600,
        cell: info => {
          const packageInBasket = info.getValue();
          return (
            <AsyncFetchCellWrapper
              field="name"
              id={`${packageInBasket.packageInternalId}.${packageInBasket.uniquePackageCode}`}
            >
              <PackageNameColumn
                id={`${packageInBasket.packageInternalId}.${packageInBasket.uniquePackageCode}`}
              />
            </AsyncFetchCellWrapper>
          );
        },
      }),
    ];
  });
};

const AsyncFetchCellWrapper = ({
  id,
  field,
  children,
}: PropsWithChildren<{
  id: UUID;
  field: "name";
}>) => {
  const { data, isLoading, error } = wmsUtilsActions.useGetPackageInfo(id);

  if (isLoading) {
    return <LoaderCell height={mainListBigUiSchema.cell.height} width={94} />;
  }

  if (error) {
    return (
      <>
        <MdiEmergencyHome color="deepOrange500" size="16" />
        <Typography fontSize="14" fontWeight="500" color="deepOrange500" className="ml-1" noWrap>
          {errorStatusToMessageDict[error._httpStatus_]}
        </Typography>
      </>
    );
  }

  if (!data?.[field]) {
    return <EmptyValue fontSize="14" fontWeight="700" />;
  }

  return <>{children}</>;
};

const PackageNameColumn = ({ id }: { id: UUID }) => {
  const { data } = wmsUtilsActions.useGetPackageInfo(id);
  assertIsDefined(data);

  return (
    <>
      <Typography fontSize="14" fontWeight="700" noWrap className="mr-1">
        {data.details?.position &&
          `${data.details.position.current}/${data.details.position.totalInCollection}`}
      </Typography>

      <Typography fontSize="14" fontWeight="700" noWrap>
        {data.name}
      </Typography>
    </>
  );
};
