import { ApiFetcher } from "hooks/createApiQuery";
import { wmsUtilsKeys } from "./keys";
import { PackageInfo } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";

const getPackageInfo = (uniquePackage: string): ApiFetcher<PackageInfo> => ({
  key: wmsUtilsKeys.packageInfo(uniquePackage),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/utils/package-info?uniquePackage=${uniquePackage}`,
    }),
});

export const wmsUtilsApi = {
  getPackageInfo,
};
